<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title>
          <template v-if="allowedAction(constants.cafereoActions)"><v-icon>mdi-note-search</v-icon>発注一覧</template>
          <template v-else><v-icon>mdi-book-search</v-icon>受注管理</template>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-checkbox
                class="mx-2"
                label="納品日未確定"
                v-model="searchModel.unfixedDeliveryTimeFlg"
                dense
              ></v-checkbox>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="発注日From"
                  dense
                  type="date"
                  v-model="searchModel.purchaseOrderDateFrom"
                  :rules="[rules.purchaseOrderDateFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="発注日To"
                  dense
                  type="date"
                  v-model="searchModel.purchaseOrderDateTo"
                  :rules="[rules.purchaseOrderDateTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="納品日From"
                  dense
                  type="date"
                  v-model="searchModel.deliveryTimeFrom"
                  :rules="[rules.deliveryTimeFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="納品日To"
                  dense
                  type="date"
                  v-model="searchModel.deliveryTimeTo"
                  :rules="[rules.deliveryTimeTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-model="searchModel.jancodeList"
                  class="mx-2"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  label="JANコード"
                  rows="1"
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="searchModel.productName"
                  class="mx-2"
                  :rules="[rules.maxLength(60)]"
                  label="商品名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.releaseDateFrom"
                  type="date"
                  label="発売日From"
                  :rules="[rules.releaseDateFrom]"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.releaseDateTo"
                  type="date"
                  label="発売日To"
                  :rules="[rules.releaseDateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <!-- <tooltip-icon-button
          v-if="allowedAction(constants.printActions) || allowedAction(constants.supplierActions)"
          icon="mdi-book-arrow-down"
          @click="onBtnPurchaseOrderFormPrint"
          :disabled="!disabledPrint"
          >発注書出力</tooltip-icon-button
        > -->
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>
    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-tabs v-model="activeStatusTab" @change="onActiveStatusTabChanged">
          <v-tab href="#approved" style="width: 180px">
            <v-badge
              v-if="allowedAction(constants.supplierActions)"
              offset-x="-1"
              :content="approvedPurchaseCount"
              :value="approvedPurchaseCount"
              >納品日未確定</v-badge
            >
          </v-tab>
          <!-- <v-tab href="#shipped" style="width: 145px" v-if="isMakerUser">納品待ち</v-tab> -->
          <v-tab href="#shipped" style="width: 145px" v-if="allowedAction(constants.supplierActions)">検品待ち</v-tab>
          <v-tab href="#arrivaled" style="width: 145px" v-if="allowedAction(constants.supplierActions)">検品済み</v-tab>
        </v-tabs>
        <ag-grid-vue
          id="PurchaseList"
          :style="gridStyle2"
          class="ag-theme-alpine"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="globalPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          rowSelection="multiple"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="onfilterChanged"
          :getRowNodeId="(data) => data.purchaseId"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        :style="gridStyle"
        v-if="detailSelected"
        id="PurchaseInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <purchase-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :purchaseId="selectPurchaseId"
          :gridStyle="gridStyle2"
        ></purchase-infos>
      </v-col>
    </v-row>
    <v-dialog v-model="bulkMakerEditDialog" persistent scrollable>
      <purchase-maker-bulk-edit
        :inputModel="selectedRows"
        @onDialogClose="onPurchaseMakerBulkEditDialogClose"
        @onEditSubmit="onPurchaseMakerBulkEditSubmit"
        v-if="bulkMakerEditDialog"
      ></purchase-maker-bulk-edit>
    </v-dialog>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
    <bottom-right-attacher>
      <template v-if="allowedAction(constants.supplierActions)">
        <v-btn large color="primary" :disabled="!rowsSelected || activeStatusTab === 'arrivaled'" @click="onBtnAnswer">
          <v-icon>mdi-calendar</v-icon>納品情報を入力
        </v-btn>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'answered'"
          @click="onBtnReserveImport"
          style="margin-right: 5px"
        >
          <v-icon>mdi-calendar-check</v-icon>納品予約取込
        </v-btn>
      </template>
    </bottom-right-attacher>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import { LockedCellRenderer, ProtectCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import PurchaseTypes from "../../consts/PurchaseTypes";
import PurchaseInfos from "./../../components/purchase/PurchaseInfos1-5.vue";
import PurchaseMakerBulkEdit from "../../components/purchase/PurchaseMakerBulkEdit.vue";
import BottomRightAttacher from "../../components/common/BottomRightAttacher.vue";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  Cafereo as CafereoColumnDefs,
  Maker1_5 as MakerColumnDefs,
  MakerAnswerConfirm1_5,
} from "../../consts/columns/PurchaseColumns";
import DateTypes from "../../consts/PurchaseImportDateTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import Validation from "../../libs/validation";
import RequestUtils from "./../../utils/RequestUtils";
import "ag-grid-autocomplete-editor/dist/main.css";
import { NumericCellEditor } from "../../components/ag-grid/cellEditors";

export default {
  name: "PurchaseSearch1-5",
  components: {
    AgGridVue,
    PurchaseInfos,
    BottomRightAttacher,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ErrorGridDialog,
    PurchaseMakerBulkEdit,
  },
  data() {
    return {
      // 権限グループ
      constants: {
        cafereoActions: ["C030301"],
        createActions: ["C030302", "C030309"],
        updateActions: ["C030303", "C030310"],
        cancelActions: ["C030304"],
        createReturnActions: ["C030305"],
        printActions: ["C030306"],
        csvActions: ["C030307"],
        sanctionActions: ["C030308"],
        changeApprovalActions: ["C030309"],
        supplierActions: ["M020201", "M020202", "M020203", "M020204", "M020205", "M020206", "M020207"],
      },
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        isNumber: Validation.isNumber,
        isMinNumber: Validation.isMinNumber,
        isJancodeSearchList: Validation.isJancodeSearchList,
        purchaseOrderDateFrom: (value) => this.purchaseOrderDateFromRules(value),
        purchaseOrderDateTo: (value) => this.purchaseOrderDateToRules(value),
        deliveryReservationFrom: (value) => this.deliveryReservationFromRules(value),
        deliveryReservationTo: (value) => this.deliveryReservationToRules(value),
        releaseDateFrom: (value) => this.releaseDateFromRules(value),
        releaseDateTo: (value) => this.releaseDateToRules(value),
      },
      dateTypes: DateTypes,
      disabledPrint: false,
      validSeearchForm: null,
      activeStatusTab: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      rowsChangeSelected: null,
      infoMaximum: false,
      purchaseQuantity: 0,
      purchaseAmount: 0,
      stockQuantity: 0,
      stockAmount: 0,
      salesAmountTotal: 0,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      statedPurchases: null,
      selectedRow: null,
      selectedRows: [],
      deliverys: [],
      purchaseModel: {
        createNew: false,
      },
      editDialog: false,
      bulkEditDialog: false,
      bulkMakerEditDialog: false,
      importDialog: { show: false, width: "800px" },
      answerDialog: { show: false, width: "800px" },
      reserveDialog: { show: false, width: "800px" },
      warehouseNames: [],
      warehouses: {},
      answerConfirmColmuns: MakerAnswerConfirm1_5,
      errorColmuns: [
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      searchModel: {
        fetchLimitOverFlg: false,
        unfixedDeliveryTimeFlg: true,
        purchaseOrderDateFrom: "",
        purchaseOrderDateTo: "",
        deliveryReservationFrom: "",
        deliveryReservationTo: "",
        deliveryTimeFrom: "",
        deliveryTimeTo: "",
        jancodes: "",
        productName: "",
        salseAgencyName: "",
        salseOriginName: "",
        releaseDateFrom: "",
        releaseDateTo: "",
        cafereoRemarks: "",
        purchasePerson: "",
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpNumericCellEditor: NumericCellEditor,
      },
      gridStyle: { height: "95%", "flex-basis": "auto" },
      gridStyle2: { height: "80%" },
    };
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = this.setColumnDefs();
    this.rowData = [];
  },
  async mounted() {
    if (this.allowedAction(this.constants.cafereoActions)) {
      this.activeStatusTab = "unapproved";
    } else if (this.isArrivalPlanMode) {
      this.activeStatusTab = "all";
    } else {
      this.activeStatusTab = "unapproved";
    }
    if (this.allowedAction(this.constants.cafereoActions)) {
      const response = await this.$store.dispatch("stock/storageSearch");
      if (response.data?.contents?.storage) {
        const storages = response.data.contents.storage;
        storages.forEach((storage) => {
          this.warehouseNames.push(storage.warehouseName);
          this.warehouses[storage.warehouseName] = storage.warehouseCd;
        });
      }
    }
    this.$nextTick(function () {
      if (this.allowedAction(this.constants.supplierActions)) {
        //this.searchModel.purchaseOrderDateFrom = moment().add(-1, "months").format("YYYY-MM-DD");
        this.onSearchClick();
      }
    });
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize + "px";
    if (this.globalPageSize > 10) {
      this.gridStyle2.height = this.gridHeightSize - 135 + "px";
    } else {
      this.gridStyle2.height = this.gridHeightSize - 135 + "px";
    }
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
      this.handleResize();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
      if (this.globalPageSize > 10) {
        this.gridStyle2.height = this.gridHeightSize - 135 + "px";
      } else {
        this.gridStyle2.height = this.gridHeightSize - 135 + "px";
      }
    },
  },
  computed: {
    isArrivalPlanMode() {
      return this.$route.name === "PurchaseArrivalPlanSearch";
    },
    approvedPurchaseCount() {
      return this.statedPurchases
        ? this.statedPurchases.unapproved.length +
            this.statedPurchases.approved.length +
            this.statedPurchases.answered.length
        : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      let detailColumns = this.columnApi
        .getAllColumns()
        .filter((column) => {
          switch (column.colDef.field) {
            case "deliveryZipCode":
            case "delivery1":
            case "delivery2":
            case "deliveryAddress":
            case "deliveryPhoneNumber":
              return true;
            default:
              return !column.colDef.hide;
          }
        })
        .map((column) => {
          let coldef = { ...column.colDef };
          return coldef;
        });
      return getDisplayDetails2(this.selectedRow.purchaseId, detailColumns, this.gridApi);
    },
    selectPurchaseId() {
      if (!this.selectedRow) {
        return null;
      }
      return this.selectedRow.purchaseId;
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    setColumnDefs() {
      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: (params) => {
            if (this.activeStatusTab === "unchangeApproved" && !params.data.productLocked) {
              return ProtectCellRenderer({ matcher: "purchaseLocked" })(params);
            } else {
              return LockedCellRenderer({ matcher: "purchaseLocked" })(params);
            }
          },
        },
      ];
      if (this.allowedAction(this.constants.cafereoActions)) {
        columnDefs = columnDefs.concat(CafereoColumnDefs);
      }
      if (this.allowedAction(this.constants.supplierActions)) columnDefs = columnDefs.concat(MakerColumnDefs);
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onActiveStatusTabChanged(activeTab) {
      console.log("activeTab::" + activeTab);
      if (this.gridApi && this.statedPurchases) {
        let coldefs = this.columnDefs;
        if (this.activeStatusTab === "shipped" || this.activeStatusTab === "arrivaled") {
          coldefs = this.columnDefs.map((column) => {
            if (column.field === "deliveryTime") column.hide = true;
            if (column.field === "deliveryReservation") column.hide = false;
            return column;
          });
        } else if (this.activeStatusTab === "approved" || this.activeStatusTab === "canceled") {
          coldefs = this.columnDefs.map((column) => {
            if (column.field === "deliveryTime") column.hide = false;
            if (column.field === "deliveryReservation") column.hide = true;
            return column;
          });
        }
        this.gridApi.setColumnDefs(coldefs);
        this.gridApi.paginationGoToFirstPage();
        if (this.allowedAction(this.constants.supplierActions) && this.activeStatusTab === "approved") {
          let rows = [];
          rows.push(...this.statedPurchases.unapproved);
          rows.push(...this.statedPurchases.approved);
          rows.push(...this.statedPurchases.answered);
          this.gridApi.setRowData(rows);
        } else {
          console.log("setRowData::");
          console.log(this.statedPurchases[activeTab]);
          this.gridApi.setRowData(this.statedPurchases[activeTab] || []);
        }
        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);
        this.purchaseQuantity = 0;
        this.purchaseAmount = 0;
        this.stockQuantity = 0;
        this.stockAmount = 0;
        this.salesAmountTotal = 0;
        for (let row of this.statedPurchases[this.activeStatusTab] || []) {
          if (row.purchaseQuantity) {
            this.purchaseQuantity += row.purchaseQuantity;
          }
          if (row.stockQuantity) {
            this.stockQuantity += row.stockQuantity;
          }
          if (row.stockPrice) {
            this.stockAmount += row.stockPrice;
          }
          if (row.purchaseQuantity && row.unitPrice) {
            this.purchaseAmount += row.purchaseQuantity * row.unitPrice;
            this.salesAmountTotal += row.purchaseQuantity * row.unitPrice;
          }
        }
      }
      this.selectedRow = null;
      this.selectedRows = [];
      this.rowsSelected = false;
      this.rowsChangeSelected = false;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = this.allowedAction(this.constants.supplierActions)
        ? "受注一覧.csv"
        : "発注一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected =
        this.selectedRows.filter((row) => {
          return !row.purchaseLocked;
        }).length > 0;
      this.rowsChangeSelected =
        this.selectedRows.filter((row) => {
          return !row.productLocked;
        }).length > 0;
      this.disabledPrint =
        this.selectedRows.filter((row) => {
          return row.purchaseType == PurchaseTypes.PURCHASE;
        }).length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;

      if (this.selectedRows.length === 1) {
        this.selectedRow = this.selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
    onfilterChanged() {
      this.purchaseQuantity = 0;
      this.purchaseAmount = 0;
      this.stockQuantity = 0;
      this.stockAmount = 0;
      this.salesAmountTotal = 0;
      this.gridApi.forEachNodeAfterFilter((row) => {
        if (row.displayed) {
          if (row.data.purchaseQuantity) {
            this.purchaseQuantity += row.data.purchaseQuantity;
          }
          if (row.data.stockQuantity) {
            this.stockQuantity += row.data.stockQuantity;
          }
          if (row.data.stockPrice) {
            this.stockAmount += row.data.stockPrice;
          }
          if (row.data.purchasePriceTotal) {
            this.purchaseAmount += row.data.purchasePriceTotal;
            this.salesAmountTotal += row.data.purchasePriceTotal;
          }
        }
      });
    },
    onBtnInsert() {
      this.purchaseModel = {
        createNew: true,
      };
      this.editDialog = true;
    },
    onBtnUpdate() {
      this.purchaseModel = {
        createNew: false,
        ...this.selectedRow,
      };
      this.editDialog = true;
    },
    onPurchaseDialogClose(redrawFlg) {
      this.editDialog = false;
      if (redrawFlg) this.onSearchClick();
    },
    onBtnBulkEdit() {
      this.bulkEditDialog = true;
    },
    onBtnImport() {
      this.importDialog.show = true;
    },
    onImportComplete() {
      this.importDialog.show = false;
    },
    onPurchaseMakerBulkEditDialogClose(rows) {
      this.bulkMakerEditDialog = false;
      if (rows.length > 0) this.onPurchaseMakerBulkEditSubmit(rows);
    },
    onPurchaseMakerBulkEditSubmit() {
      this.onSearchClick();
      this.bulkMakerEditDialog = false;
    },
    async onBtnCancel() {
      for (const row of this.selectedRows) {
        if (row.purchaseStatus == 7 && row.receiptScheduleDetailsNo) {
          await this.$dialog.warning({
            title: "注意",
            text: `翌日入荷データ出力されている発注が含まれています`,
            actions: ["OK"],
          });
          break;
        }
      }
      let messageText = `選択された発注をキャンセルします`;
      const ok = await this.$dialog.confirm({ title: "発注一覧", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.selectedRows.forEach((row) => {
            updateModels.push({
              purchaseId: row.purchaseId,
              lastUpdateDatetime: row.updateDate,
            });
          });
          var requestContents = {
            cancelConditions: updateModels,
          };
          const result = await this.$store.dispatch("purchase/cancel", requestContents);

          let error = result.data?.header;

          const afterRows = [];
          const removeRow = [];
          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    purchaseId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (result.data.contents?.purchases) {
                // 更新分のレコード変更
                this.statedPurchases[this.activeStatusTab].forEach((statusData) => {
                  let diffFlg = false;
                  result.data.contents.purchases.forEach((purchase) => {
                    if (purchase.purchaseId == statusData.purchaseId) {
                      statusData.purchaseStatus = purchase.status;
                      statusData.updateDate = purchase.updateDate;
                      this.statedPurchases.canceled.push(statusData);
                      removeRow.push(statusData);
                      diffFlg = true;
                    }
                  });
                  if (!diffFlg) afterRows.push(statusData);
                });
                this.statedPurchases[this.activeStatusTab] = afterRows;
              }
              if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "発注キャンセル", records: errorRows });
                this.$dialog.notify.error(`発注キャンセルに失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`発注がキャンセルされました`, { timeout: 2300 });
                this.$refs.reserveConfirmGrid.close();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseSearch::onBtnCancel", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    // 検索処理
    async onSearchClick() {
      try {
        this.loadingOn();
        // jancodeのリスト化
        this.searchModel.jancode = this.searchModel.jancodeList
          ? this.searchModel.jancodeList
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        const response = await this.$store.dispatch("purchase/search", { searchConditions: this.searchModel });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = this.allowedAction(this.constants.supplierActions) ? "受注管理" : "発注一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }

        let title = "";
        let tmpContents = null;
        if (this.allowedAction(this.constants.supplierActions)) {
          title = "受注管理";
          tmpContents = result.orders;
        } else {
          title = "発注一覧";
          tmpContents = result.purchases;
        }
        if (Object.keys(tmpContents).length === 0) {
          this.$dialog.warning({
            title: title,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.statedPurchases = response.data.contents.statedPurchases || {};
        if (this.activeStatusTab === "approved") {
          let rows = [];
          rows.push(...this.statedPurchases.unapproved);
          rows.push(...this.statedPurchases.approved);
          rows.push(...this.statedPurchases.answered);
          this.gridApi.setRowData(rows);
        } else {
          this.gridApi.setRowData(this.statedPurchases[this.activeStatusTab] || []);
        }

        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);
        this.purchaseQuantity = 0;
        this.purchaseAmount = 0;
        this.stockQuantity = 0;
        this.stockAmount = 0;
        this.salesAmountTotal = 0;
        for (let row of this.statedPurchases[this.activeStatusTab] || []) {
          if (row.purchaseQuantity) {
            this.purchaseQuantity += row.purchaseQuantity;
          }
          if (row.stockQuantity) {
            this.stockQuantity += row.stockQuantity;
          }
          if (row.stockPrice) {
            this.stockAmount += row.stockPrice;
          }
          if (row.purchasePriceTotal) {
            this.purchaseAmount += row.purchasePriceTotal;
            this.salesAmountTotal += row.purchasePriceTotal;
          }
        }
      } catch (error) {
        console.error("PurchaseSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },

    // 発注書印刷
    async onBtnPurchaseOrderFormPrint() {
      let messageText = `選択されたデータの発注書を印刷します。`;
      const ok = await this.$dialog.confirm({ title: "発注書印刷", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          const purchaseRows = this.gridApi.getSelectedRows();
          const purchases = [];
          purchaseRows.forEach((purchase) => {
            purchases.push(purchase.purchaseId);
          });

          var requestContents = {
            purchases: purchases,
          };
          const result = await this.$store.dispatch("purchase/print", requestContents);

          let error = result.data?.header;
          const errorRows = [];

          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              window.open(result.data.contents.exportFilePath, "_blank");
              this.$dialog.notify.info(`発注書を印刷しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    purchaseId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              this.$refs.bulkErrorGrid.open({ title: "発注書印刷", records: errorRows });
              this.$dialog.notify.error(`発注書印刷に失敗したデータが存在します。ご確認ください。`, {
                timeout: 2300,
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseSearch::onBtnPurchaseOrderFormPrint", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    // 納品予定日回答処理
    onBtnAnswerImport() {
      this.answerDialog.show = true;
    },
    onAnswerImportDialogClose() {
      this.answerDialog.show = false;
    },
    async onAnswerImportSubmit(records) {
      this.answerDialog.show = false;
      const removeRow = [];
      const afterRows = [];
      if (records) {
        this.statedPurchases.approved.forEach((approval) => {
          let diffFlg = false;
          records.forEach((order) => {
            if (order.purchaseId == approval.purchaseId) {
              approval.purchaseStatus = order.status;
              approval.deliveryTime = order.deliveryTime;
              approval.updateDate = order.updateDate;
              this.statedPurchases.answered.push(approval);
              removeRow.push(approval);
              diffFlg = true;
            }
          });
          if (!diffFlg) afterRows.push(approval);
        });
      }
      this.statedPurchases.approved = afterRows;
      if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
    },
    async onBtnAnswer() {
      this.bulkMakerEditDialog = true;
    },
    async onBtnAnswerSubmit(records) {
      try {
        this.loadingOn();
        let updateModels = [];
        const validError = [];
        records.forEach((row) => {
          const error = this.rules.required(row.deliveryTime);
          if (error === true) {
            updateModels.push({
              purchaseId: row.purchaseId,
              deliveryTime: row.deliveryTime,
              updateDatetime: row.updateDate,
            });
          } else {
            validError.push({
              purchaseId: row.purchaseId,
              errorMessage: ["納品予定日は" + error],
            });
          }
        });
        if (validError.length > 0) {
          this.$refs.bulkErrorGrid.open({ title: "納品予定日回答", records: validError });
          this.$dialog.notify.error(`入力チェックエラーがあります。`, {
            timeout: 2300,
          });
          return;
        }
        // 最大件数チェック
        const validDataSizeRet = await RequestUtils.validDataSize("M020203", updateModels.length, (limitSize) => {
          this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
            timeout: 2300,
          });
          this.loadingOff();
        });
        if (!validDataSizeRet) {
          return;
        }
        // WAF対策で引数をすべてさきにAPIに送る
        // 3000件毎にスライス
        const sliceByNumber = (array, number) => {
          const length = Math.ceil(array.length / number);
          return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
        };
        let requestRecordsSplit = sliceByNumber(updateModels, 3000);
        let bulkId = null;
        // APIでマージして引数を保持
        for (let i = 0; i < requestRecordsSplit.length; i++) {
          var payloadParam = {
            orders: requestRecordsSplit[i],
            bulkId: bulkId,
          };
          console.log(payloadParam);
          const response = await this.$store.dispatch("purchase/bulkPayloadShipdate", payloadParam);
          let error = response.data?.header;
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              bulkId = response.data.contents.bulkId;
              break;
            default:
              this.redirectError();
              break;
          }
        }
        let exec = true;
        let headers = null;
        let content = {
          bulkId: bulkId,
        };
        while (exec) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const result = await this.$store.dispatch("purchase/answer", {
            contents: content,
            header: headers,
          });

          let error = result.data?.header;

          const afterRows = [];
          const removeRow = [];
          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              exec = false;
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    purchaseId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 更新分のレコード変更
              if (result.data?.contents?.orders) {
                this.statedPurchases.approved.forEach((approval) => {
                  let diffFlg = false;
                  result.data.contents.orders.forEach((order) => {
                    if (order.purchaseId == approval.purchaseId) {
                      approval.purchaseStatus = order.status;
                      approval.deliveryTime = order.deliveryTime;
                      approval.updateDate = order.updateDate;
                      this.statedPurchases.answered.push(approval);
                      removeRow.push(approval);
                      diffFlg = true;
                    }
                  });
                  if (!diffFlg) afterRows.push(approval);
                });
              }
              this.statedPurchases.approved = afterRows;
              if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
              if (errorRows.length > 0) {
                if (removeRow.length > 0) this.$refs.answerConfirmGrid.gridApi.applyTransaction({ remove: removeRow });
                this.$refs.bulkErrorGrid.open({ title: "納品予定日回答", records: errorRows });
                this.$dialog.notify.error(`納品予定日回答処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`納品予定日を回答しました`, { timeout: 2300 });
                this.$refs.answerConfirmGrid.close();
              }
              break;
            case ApiStatus.consts.PROCESSING:
              headers = { InvokeId: result.data.header.invokeId };
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("PurchaseSearch::onBtnAnswer", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    // 納品予約処理
    onBtnReserveImport() {
      this.reserveDialog.show = true;
    },
    onReserveImportDialogClose() {
      this.reserveDialog.show = false;
    },
    async onReserveImportSubmit(records) {
      this.reserveDialog.show = false;
      const removeRow = [];
      const afterRows = [];
      if (records) {
        this.statedPurchases.answered.forEach((approval) => {
          let diffFlg = false;
          records.forEach((order) => {
            if (order.purchaseId == approval.purchaseId) {
              approval.purchaseStatus = order.status;
              approval.deliveryReservation = order.deliveryReservation;
              approval.updateDate = order.updateDate;
              this.statedPurchases.shipped.push(approval);
              removeRow.push(approval);
              diffFlg = true;
            }
          });
          if (!diffFlg) afterRows.push(approval);
        });
      }
      this.statedPurchases.answered = afterRows;
      if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
    },
    async onBtnReserve() {
      this.$refs.reserveConfirmGrid.open({
        records: JSON.parse(
          JSON.stringify(
            this.gridApi.getSelectedRows().filter((row) => {
              return !row.purchaseLocked;
            })
          )
        ),
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            const validError = [];
            records.forEach((row) => {
              const error = this.rules.required(row.deliveryReservation);
              if (error === true) {
                updateModels.push({
                  purchaseId: row.purchaseId,
                  deliveryReservation: row.deliveryReservation,
                  updateDatetime: row.updateDate,
                });
              } else {
                validError.push({
                  purchaseId: row.purchaseId,
                  errorMessage: ["納品予約は" + error],
                });
              }
            });
            if (validError.length > 0) {
              this.$refs.bulkErrorGrid.open({ title: "納品予約", records: validError });
              this.$dialog.notify.error(`入力チェックエラーがあります。`, {
                timeout: 2300,
              });
              return;
            }
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("M020204", updateModels.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            // WAF対策で引数をすべてさきにAPIに送る
            // 3000件毎にスライス
            const sliceByNumber = (array, number) => {
              const length = Math.ceil(array.length / number);
              return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
            };
            let requestRecordsSplit = sliceByNumber(updateModels, 3000);
            let bulkId = null;
            // APIでマージして引数を保持
            for (let i = 0; i < requestRecordsSplit.length; i++) {
              var payloadParam = {
                orders: requestRecordsSplit[i],
                bulkId: bulkId,
              };
              console.log(payloadParam);
              const response = await this.$store.dispatch("purchase/bulkPayloadDeliveryreservation", payloadParam);
              let error = response.data?.header;
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                  bulkId = response.data.contents.bulkId;
                  break;
                default:
                  this.redirectError();
                  break;
              }
            }
            let exec = true;
            let headers = null;
            let content = {
              bulkId: bulkId,
            };
            while (exec) {
              await new Promise((resolve) => setTimeout(resolve, 1000));
              const result = await this.$store.dispatch("purchase/reserve", {
                contents: content,
                header: headers,
              });

              let error = result.data?.header;

              const afterRows = [];
              const removeRow = [];
              const errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  exec = false;
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        purchaseId: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 更新分のレコード変更
                  if (result.data?.contents?.orders) {
                    this.statedPurchases.answered.forEach((approval) => {
                      let diffFlg = false;
                      result.data.contents.orders.forEach((order) => {
                        if (order.purchaseId == approval.purchaseId) {
                          approval.purchaseStatus = order.status;
                          approval.deliveryReservation = order.deliveryReservation;
                          approval.updateDate = order.updateDate;
                          this.statedPurchases.shipped.push(approval);
                          removeRow.push(approval);
                          diffFlg = true;
                        }
                      });
                      if (!diffFlg) afterRows.push(approval);
                    });
                  }
                  this.statedPurchases.answered = afterRows;

                  if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                  if (errorRows.length > 0) {
                    if (removeRow.length > 0)
                      this.$refs.reserveConfirmGrid.gridApi.applyTransaction({ remove: removeRow });
                    this.$refs.bulkErrorGrid.open({ title: "納品予約", records: errorRows });
                    this.$dialog.notify.error(`納品予約処理に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`納品予約が完了しました`, { timeout: 2300 });
                    this.$refs.reserveConfirmGrid.close();
                  }
                  break;
                case ApiStatus.consts.PROCESSING:
                  headers = { InvokeId: result.data.header.invokeId };
                  break;
                default:
                  exec = false;
                  this.redirectError();
                  break;
              }
            }
          } catch (error) {
            console.error("PurchaseSearch::onBtnReserve", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    purchaseOrderDateFromRules(value) {
      if (value == null || this.searchModel.purchaseOrderDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.purchaseOrderDateTo)) return "発注日Toより前の日付を指定してください";
      return true;
    },
    purchaseOrderDateToRules(value) {
      if (value == null || this.searchModel.purchaseOrderDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.purchaseOrderDateFrom))
        return "発注日Fromより後の日付を指定してください";
      return true;
    },
    deliveryReservationFromRules(value) {
      if (value == null || this.searchModel.deliveryReservationTo == null) return true;
      if (moment(value).isAfter(this.searchModel.deliveryReservationTo))
        return "納品予約Toより前の日付を指定してください";
      return true;
    },
    deliveryReservationToRules(value) {
      if (value == null || this.searchModel.deliveryReservationFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.deliveryReservationFrom))
        return "納品予約Fromより後の日付を指定してください";
      return true;
    },
    releaseDateFromRules(value) {
      if (value == null || this.searchModel.releaseDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.releaseDateTo)) return "発売日Toより前の日付を指定してください";
      return true;
    },
    releaseDateToRules(value) {
      if (value == null || this.searchModel.releaseDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.releaseDateFrom)) return "発売日Fromより後の日付を指定してください";
      return true;
    },
    returnQuantityRule(value, purchaseQuantity) {
      if (value == null || !value.match(/^([1-9]\d*|0)$/)) return true;
      if (Number(value) > purchaseQuantity) return "返品数は発注数以下の数値を指定してください";
      return true;
    },
    onValidPurchaseReturn(row) {
      var ret = true;
      var messages = [];

      // 返品数
      this.setValidMessage(this.rules.required(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isNumber(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.returnQuantityRule(row.returnQuantity, row.purchaseQuantity), "返品数", messages);
      // 商品状態
      this.setValidMessage(this.rules.required(row.productConditionType), "商品状態区分", messages);
      // 倉庫
      this.setValidMessage(this.rules.required(row.warehouseCd), "倉庫", messages);
      // 配送先
      this.setValidMessage(this.rules.required(row.supplierDeliveryCode?.value), "配送先", messages);
      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
  },
};
</script>
